"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DFCAnalyticsEmitter = void 0;
var DFCAnalyticsEmitter = (function () {
    function DFCAnalyticsEmitter(onAnalyticsEvent) {
        this.onAnalyticsEvent = onAnalyticsEvent;
    }
    DFCAnalyticsEmitter.prototype.trackEvent = function (event) {
        this.onAnalyticsEvent(event);
    };
    DFCAnalyticsEmitter.prototype.identify = function (event) {
        this.onAnalyticsEvent(event);
    };
    return DFCAnalyticsEmitter;
}());
exports.DFCAnalyticsEmitter = DFCAnalyticsEmitter;
