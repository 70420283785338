"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DFCNetworkAPIResultModel = exports.DFCNetworkAPIErrorType = void 0;
var DFCNetworkAPIErrorType;
(function (DFCNetworkAPIErrorType) {
    DFCNetworkAPIErrorType["ErrorDetails"] = "ErrorDetails";
    DFCNetworkAPIErrorType["Unknown"] = "Unknown";
    DFCNetworkAPIErrorType["WrongInput"] = "WrongInput";
    DFCNetworkAPIErrorType["Parsing"] = "Parsing";
    DFCNetworkAPIErrorType["EmptyResponse"] = "EmptyResponse";
    DFCNetworkAPIErrorType["Forbidden"] = "Forbidden";
    DFCNetworkAPIErrorType["Unauthorised"] = "Unauthorised";
    DFCNetworkAPIErrorType["SessionMissing"] = "SessionMissing";
    DFCNetworkAPIErrorType["CacheMissing"] = "CacheMissing";
    DFCNetworkAPIErrorType["Timeout"] = "Timeout";
    DFCNetworkAPIErrorType["Conflict"] = "Conflict";
})(DFCNetworkAPIErrorType = exports.DFCNetworkAPIErrorType || (exports.DFCNetworkAPIErrorType = {}));
var DFCNetworkAPIResultModel = (function () {
    function DFCNetworkAPIResultModel(fromCache, jsonStr, httpStatusCode, error) {
        this.jsonStr = jsonStr;
        this.fromCache = fromCache;
        this.error = error;
        this.httpStatusCode = httpStatusCode;
    }
    Object.defineProperty(DFCNetworkAPIResultModel.prototype, "networkAPIErrorModel", {
        get: function () {
            var errorResponse;
            errorResponse = JSON.parse(this.jsonStr);
            return {
                response: errorResponse,
                error: this.error,
                httpStatusCode: this.httpStatusCode,
            };
        },
        enumerable: false,
        configurable: true
    });
    return DFCNetworkAPIResultModel;
}());
exports.DFCNetworkAPIResultModel = DFCNetworkAPIResultModel;
