"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DFCLogEmitter = exports.DFCLogType = void 0;
var DFCLogType;
(function (DFCLogType) {
    DFCLogType["DEBUG"] = "DEBUG";
    DFCLogType["INFO"] = "INFO";
    DFCLogType["WARN"] = "WARN";
    DFCLogType["ERROR"] = "ERROR";
})(DFCLogType = exports.DFCLogType || (exports.DFCLogType = {}));
var DFCLogEmitter = (function () {
    function DFCLogEmitter(onLogEvent, enableDebugMode) {
        this.enableDebugMode = false;
        this.onLogEvent = onLogEvent;
        this.enableDebugMode = enableDebugMode;
    }
    DFCLogEmitter.prototype.error = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, DFCLogType.ERROR);
    };
    DFCLogEmitter.prototype.debug = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, DFCLogType.DEBUG);
    };
    DFCLogEmitter.prototype.info = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, DFCLogType.INFO);
    };
    DFCLogEmitter.prototype.warn = function (message, additionalInfo) {
        if (additionalInfo === void 0) { additionalInfo = null; }
        this.log(message, additionalInfo, DFCLogType.WARN);
    };
    DFCLogEmitter.prototype.log = function (message, additionalInfo, type) {
        if (!this.enableDebugMode && type === DFCLogType.DEBUG) {
            return;
        }
        this.onLogEvent(message, additionalInfo, type);
    };
    return DFCLogEmitter;
}());
exports.DFCLogEmitter = DFCLogEmitter;
