"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DFCNetworkAPIRequestModel = void 0;
var DFCNetworkAPIRequestModel = (function () {
    function DFCNetworkAPIRequestModel(url, method, baseURL, headers, data, params, timeout, onUploadProgress, onDownloadProgress) {
        this.url = url;
        this.method = method;
        this.baseURL = baseURL;
        this.headers = headers;
        this.data = data;
        this.params = params;
        this.timeout = timeout;
        this.onUploadProgress = onUploadProgress;
        this.onDownloadProgress = onDownloadProgress;
    }
    return DFCNetworkAPIRequestModel;
}());
exports.DFCNetworkAPIRequestModel = DFCNetworkAPIRequestModel;
